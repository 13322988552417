import React from "react";
import { SEO, PageLayout } from "@bluefin/components";
import { Grid, Header, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class SessionsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"sessions-page"}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12} textAlign={"left"}>
              <Header as={"h1"}>Shamanic Sessions</Header>
              <Image
                src={
                  "https://fisherman.gumlet.io/public/dc987ac5-d1e6-456f-82af-98b3ef5c7216/ezgif-3-b6a1e4ea7a.jpeg"
                }
                fluid={true}
                style={{ marginBottom: "1rem" }}
              />
              <p>
                Our seven chakras (explained below) are the energy centers of
                the body when these centers get blocked or off balance from
                stress, trauma, chronic illness, toxic environment and other
                disruptors it can negatively affect our physical, emotional and
                mental well being causing mild depression, lack of energy,
                physical ailments, slowed organ functions and a suppressed
                immune system. As well as blocking the energetics of what each
                chakra contributes to your being. Chakra balancing can also be
                like a tune up to make sure you stay functioning at optimal
                wellness.
              </p>
              <ul>
                <li>
                  Crown chakra (top of the head) - our spirituality, our sense
                  of direction in life.
                </li>
                <li>
                  Third eye/brow chakra (between the brows) - our intuition, our
                  thoughts.
                </li>
                <li>
                  Throat chakra (centre of the throat) - our communication,
                  speaking our truth.
                </li>
                <li
                  href={
                    "https://www.therapy-directory.org.uk/memberarticles/increasing-your-reiki-energy-flow-the-heart-chakra"
                  }
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <a>Heart chakra </a>
                  <span>
                    (centre of the chest) - our ability to love and be loved,
                    relationships.
                  </span>
                </li>
                <li>
                  Solar plexus chakra (below the ribs) - our ego, self-esteem,
                  who we are, creativity.
                </li>
                <li>
                  Sacral chakra (below the belly button) - our sexuality,
                  yin-yang energies, emotions.
                </li>
                <li>
                  Root/base chakra (base of the spine) - our survival, security,
                  and being safe/grounded.
                </li>
              </ul>
              <p>
                In chakra balancing sessions the chakra system of an individual
                is assessed, recalibrated and reinforced with sacred Shamanic
                ceremony that includes smudging, sound healing, breath work and
                energetic support in releasing stuck emotions, trauma, stress
                and other negative energies that may be trapped in the physical
                and energetic body. This work creates a brighter, lighter and
                fully functioning being!
              </p>
              <p>
                Chakra balancing session with light body work, focusing on parts
                of the body that may also need body work. $160 (75 minutes to 90
                minutes)
              </p>
              <p>
                Guided shamanic drumming sessions to find your power, find your
                own guidance, for a connection with spirit, for creative
                inspiration and motivation, for forging through blocks of any
                kind. 45 minutes $90
              </p>
              <p>
                In depth personalized Shamanic healing sessions are offered, the
                payment is discussed around the work that comes up and the care
                that is needed. Please email for more information.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Sessions" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
